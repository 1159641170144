require('./components/cockpit');
require('./components/database');
require('./components/filter');
require('./components/management');
require('./components/mobile-menu');
require('./components/my-alerts');
require('./components/switcher');
require('./components/thematic-alerts');
require('./components/trackable-links');

(function ($) {
    $(document).ready(() => {
        // Custom selects
        $('select').wrap('<div class="customSelectContainer"/>').customSelect();

        // Tooltips
        $('[data-tooltip]').tooltipster({
            contentAsHTML: true,
            theme: 'tooltipster-borderless',
        });

        // Remove the "no-js" class from body
        $('body').removeClass('no-js');
    });
}(jQuery));
