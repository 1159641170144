(function (factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD
        define(['jquery'], factory);
    } else if (typeof module !== 'undefined' && module.exports) {
        // CommonJS
        module.exports = factory(require('jquery'));
    } else {
        // Global
        factory(jQuery);
    }
}(($) => {
    $(document).ready(() => {
        const component = $('[data-component="thematic-alerts"]');

        if (component.length < 1) {
            return;
        }

        const fields = component.find('[data-element="fields"]').find('input[type="checkbox"]');

        component.find('[data-element="check-all"]').on('click', (e) => {
            e.preventDefault();
            fields.prop('checked', true);
        });

        component.find('[data-element="uncheck-all"]').on('click', (e) => {
            e.preventDefault();
            fields.prop('checked', false);
        });
    });
}));
