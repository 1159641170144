(function (factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD
        define(['jquery'], factory);
    } else if (typeof module !== 'undefined' && module.exports) {
        // CommonJS
        module.exports = factory(require('jquery'));
    } else {
        // Global
        factory(jQuery);
    }
}(($) => {
    $(document).ready(() => {
        $('a[data-component="trackclick"]').on('click', function () {
            const link = $(this);
            $.get(`trackclick?url=${encodeURIComponent(link.attr('href'))}&linkid=${link.data('id')}&utitle=${link.text()}&txt=${link.data('category')}&loc=${document.location}`);
        });
    });
}));
