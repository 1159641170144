(function (factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD
        define(['jquery'], factory);
    } else if (typeof module !== 'undefined' && module.exports) {
        // CommonJS
        module.exports = factory(require('jquery'));
    } else {
        // Global
        factory(jQuery);
    }
}(($) => {
    $(document).ready(() => {
        const component = $('[data-component="cockpit"]');

        if (component.length < 1) {
            return;
        }

        const storageKey = 'cockpitClosed';
        const toggler = component.find('[data-element="toggler"]');
        const content = component.find('[data-element="content"]');

        // Open the cockpit if not saved as closed
        if (!window.localStorage.getItem(storageKey)) {
            content.addClass('active');
            toggler.addClass('active');
        }

        toggler.on('click', function () {
            const el = $(this);

            if (el.hasClass('active')) {
                content.removeClass('active');
                window.localStorage.setItem(storageKey, 1);
            } else {
                content.addClass('active');
                window.localStorage.removeItem(storageKey);
            }

            el.toggleClass('active');
        });
    });
}));
