(function (factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD
        define(['jquery'], factory);
    } else if (typeof module !== 'undefined' && module.exports) {
        // CommonJS
        module.exports = factory(require('jquery'));
    } else {
        // Global
        factory(jQuery);
    }
}(($) => {
    $(document).ready(() => {
        const el = $('#mobile-menu');

        $('#header').find('.mobile-menu-trigger').on('click', () => el.data('mmenu').open());

        el.mmenu({
            extensions: ['pagedim-black'],
            slidingSubmenus: false,
            scrollBugFix: { fix: false },
        }, {
            classNames: { selected: 'active' },
            offCanvas: { pageSelector: '#wrapper' },
        });
    });
}));
