(function (factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD
        define(['jquery'], factory);
    } else if (typeof module !== 'undefined' && module.exports) {
        // CommonJS
        module.exports = factory(require('jquery'));
    } else {
        // Global
        factory(jQuery);
    }
}(($) => {
    $(document).ready(() => {
        const component = $('[data-component="filter"]');

        if (component.length < 1) {
            return;
        }

        component.find('select[data-element="toggler"]').on('change', function () {
            this.form.submit();
        });
    });
}));
