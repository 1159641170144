(function (factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD
        define(['jquery'], factory);
    } else if (typeof module !== 'undefined' && module.exports) {
        // CommonJS
        module.exports = factory(require('jquery'));
    } else {
        // Global
        factory(jQuery);
    }
}(($) => {
    $(document).ready(() => {
        const component = $('[data-component="my-alerts"]');

        if (component.length > 1) {
            return;
        }

        component.find('.my-alerts').find('table').resTables({
            span: [1],
            move: { 1: 0 },
        });

        component.find('.thematic-alerts').find('table').resTables({
            span: [2],
            move: { 2: 0 },
        });
    });
}));
