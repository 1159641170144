(function (factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD
        define(['jquery'], factory);
    } else if (typeof module !== 'undefined' && module.exports) {
        // CommonJS
        module.exports = factory(require('jquery'));
    } else {
        // Global
        factory(jQuery);
    }
}(($) => {
    $(document).ready(() => {
        const component = $('[data-component="management"]');

        if (component.length < 1) {
            return;
        }

        component.find('table').resTables({
            span: [0],
            skip: [1],
            merge: { 6: [7] },
            cloneCallback: clone => clone.find('.checkbox-orphan').removeClass('checkbox-orphan'),
        });
    });
}));
